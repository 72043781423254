.geosuggest {
    font-size: 15px;
    font-size: 1rem;
    position: relative;
    width: 100%;
    margin: 1em auto;
    padding-bottom: 0px;
    text-align: left;
    border: none;
  }
  .geosuggest__input {
    width: 100%;
    border: none;
    border-bottom: 0px solid #e0e0e0;
    box-shadow: 0 0 0px #3d464d;
    font-size: 15px;
    padding: .5em 1em;
    padding-left: 0px;
    -webkit-transition: border 0.2s, box-shadow 0.2s;
            transition: border 0.2s, box-shadow 0.2s;
  }
  .geosuggest__input:focus {
    border-color: #267dc0;
    box-shadow: none;
    outline-width: 0;
    outline: none;
    border-bottom: 0px solid #fafafa);
  }
  .geosuggest__suggests {
    position: relative;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 0px solid #e0e0e0;
    border-radius: 0px;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
            transition: max-height 0.2s, border 0.2s;
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }
  
  /**
   * A geosuggest item
   */
  .geosuggest__item {
    font-size: 15px;
    padding: .5em .65em;
    cursor: pointer;
  }
  .geosuggest__item:hover,
  .geosuggest__item:focus {
    background: #f5f5f5;
  }
  .geosuggest__item--active {
    background: #267dc0;
    color: #fff;
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: #ccc;
  }
  .geosuggest__item__matched-text {
    font-weight: bold;
  }